
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onBeforeMount,
  computed,
} from "vue";
import UpdateLogoPopup from "@/components/popups/UpdateLogoPopup.vue";
import UpdateTextPopup from "@/components/popups/UpdateTextPopup.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import { useRoute } from "vue-router";
import { MemberRepository, RepositoryFactory } from "@/lib/https";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import ListItemEditInfo from "@/components/atomics/list-item/ListItemEditInfo.vue";
import { useI18n } from "vue-i18n";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import UpdateNamePopup from "@/components/popups/UpdateNamePopup.vue";
import UpdateRoleMemberPopup from "@/components/popups/member/UpdateRoleMemberPopup.vue";
import Tag from "@/components/atomics/Tag.vue";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import { AnonymousFunction } from "@/models/Function";

export default defineComponent({
  name: "MemberDetail",
  components: {
    UpdateLogoPopup,
    UpdateTextPopup,
    BreadScrum,
    AfterLoginPage,
    ListItemEditInfo,
    UpdateNamePopup,
    UpdateRoleMemberPopup,
    Tag,
  },
  setup() {
    const { t } = useI18n();
    const { openNotification } = useNotificationControl();
    const { popupState, openPopup } = usePopupControl();

    const state = reactive({
      memberData: {
        id: "",
        lastName: "",
        firstName: "",
        name: "",
        position: "",
        profilePicture: require('icons/default-avatar.svg'),
        phoneNumber: "",
        email: "",
        role: 1,
        roleName: "",
        companyName: "",
        canUpdate: false,
      },
      logoToEdit: {
        logo: "",
        title: t("member.editSymbol"),
        memberID: "",
        name: "",
        action: async (logo: string) => {
          const formData = new FormData() ;
          formData.append("profilePicture", logo);
          await updateProfilePicture(state.memberData.id, formData );
        },
      },
      fieldToEdit: {
        name: "",
        value: "",
        title: "",
        label: "",
        action: AnonymousFunction,
      },
      nameToEdit: {
        lastName: "",
        firstName: "",
        name: "",
        title: t("member.editFamilyName"),
        action: async (lastName: string, firstName: string) => {
          const formData = new FormData();
          formData.append("lastName", lastName);
          formData.append("firstName", firstName);
          await updateFullName(state.memberData.id, formData);
        },
      },
    });

    const infoRows = computed(() => [
      {
        title: t("member.titleSymbol"),
        value: state.memberData.profilePicture,
        action: () => {
          state.logoToEdit.name = "imprint";
          state.logoToEdit.title = t("company.titleStampImageEdit");
          state.logoToEdit.logo = state.memberData.profilePicture
          ? state.logoToEdit.logo = state.memberData.profilePicture + `?${Date.now()}`
          : require('icons/default-avatar.svg');
          updateProfilePicture
          openPopup("updateLogo");
        },
        isLogo: true,
      },

      {
        title: t("member.titleFamilyName"),
        value: isEdit
          ? `${state.memberData.lastName} ${state.memberData.firstName}`
          : `${state.memberData.name}`,
        action: () => {
          state.nameToEdit.lastName = state.memberData.lastName;
          state.nameToEdit.firstName = state.memberData.firstName;
          openPopup("updateName");
        },
      },
      {
        title: t("member.titlePosition"),
        value: state.memberData.position,
        action: () => {
          state.fieldToEdit.name = "position";
          state.fieldToEdit.value = state.memberData.position;
          state.fieldToEdit.title = t("member.editPosition");
          state.fieldToEdit.label = t("member.editPositionLabel");
          state.fieldToEdit.action = updatePosition;
          openPopup("updateText");
        },
      },
      {
        title: t("member.titleTelephoneNumber"),
        value: state.memberData.phoneNumber,
        action: () => {
          state.fieldToEdit.name = "phoneNumber";
          state.fieldToEdit.value = state.memberData.phoneNumber;
          state.fieldToEdit.title = t("member.editTelephoneNumber");
          state.fieldToEdit.label = t("member.editTelephoneNumberLabel");
          state.fieldToEdit.action = updatePhoneNumber;
          openPopup("updateText");
        },
      },
      {
        title: t("member.titleEmailAddress"),
        value: state.memberData.email,
        action: () => {
          state.fieldToEdit.name = "email";
          state.fieldToEdit.value = state.memberData.email;
          state.fieldToEdit.title = t("member.editEmailAddress");
          state.fieldToEdit.label = t("member.editEmailAddressLabel");
          state.fieldToEdit.action = updateEmail;
          openPopup("updateText");
        },
      },
      {
        title: t("company.titleCompanyName"),
        value: state.memberData.companyName,
        action: () => {
          state.fieldToEdit.name = "companyName";
          state.fieldToEdit.value = state.memberData.companyName;
          state.fieldToEdit.title = t("member.editCompanyName");
          state.fieldToEdit.label = t("member.editCompanyNameLabel");
          state.fieldToEdit.action = updateCompanyName;
          openPopup("updateText");
        },
      },
      {
        title: t("member.titleAcountType"),
        value: t("member.role" + state.memberData.role),
        action: () => {
          openPopup("updateRoleMember");
        },
        isTag: true,
      },
    ]);

    const route = useRoute();
    const isEdit = route.query.isInviting === "false";
    const memberID = route.params.memberID;
    const databreadScrumLinks = ref([
      { text: "顧客一覧", to: "/member" },
      { text: "メンバープロフィール", to: "/member/" + memberID },
    ]);

    const {
      getMemberProfile,
      updateFullName,
      updatePosition,
      updatePhoneNumber,
      updateEmail,
      updateCompanyName,
      updateProfilePicture,
      getInviteMemberProfile,
    } = RepositoryFactory.getRepository<MemberRepository>(MemberRepository);

    const getMemberDetail = async () => {
      if (isEdit) {
        const memberPayload = await getMemberProfile(memberID);
        state.memberData = memberPayload;
        state.memberData.profilePicture = memberPayload.profilePicture
          ? process.env.VUE_APP_API_CLOUD_URL + memberPayload.profilePicture
          : "";
      } else {
        const memberPayload = await getInviteMemberProfile(memberID);
        state.memberData = memberPayload;
      }
    };
    onBeforeMount(getMemberDetail);

    const editTextPopupInfo = computed(() => ({
      initialTextValue: state.fieldToEdit.value,
      title: state.fieldToEdit.title,
      label: state.fieldToEdit.label,
      placeholder: state.fieldToEdit.label,
      fieldName: state.fieldToEdit.name,
      action: (formData: FormData) => {
        state.fieldToEdit.action(state.memberData.id, formData);
      },
    }));

    return {
      ...toRefs(state),
      databreadScrumLinks,
      infoRows,
      popupState,
      editTextPopupInfo,
      openNotification,
      getMemberDetail,
      isEdit,
    };
  },
});


import { defineComponent, ref } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import { useI18n } from "vue-i18n";
import { MemberRepository, RepositoryFactory } from "@/lib/https";

export default defineComponent({
  name: "UpdateRoleMemberPopup",
  components: {
    Popup,
    FlatButton,
    Dropdown,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    role: {
      type: Number,
      default: 3,
    },
    id: {
      type: String,
      required: true,
    },
  },
  emits: ["onUpdateRoleDone"],
  setup(props, { emit }) {
    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "updateRoleMember";
    const { t } = useI18n();

    const roleOptions = [
      {
        id: 1,
        name: t("member.role1"),
      },
      {
        id: 2,
        name: t("member.role2"),
      },
      {
        id: 3,
        name: t("member.role3"),
      },
    ];

    const { updateRole } =
      RepositoryFactory.getRepository<MemberRepository>(MemberRepository);

    const editRole = ref(props.role);

    const updateText = async () => {
      const formData = new FormData();
      formData.append("role", String(editRole.value));
      await updateRole(props.id, formData);
      emit("onUpdateRoleDone");
      closePopup(popupKey);
    };

    return {
      popupKey,
      updateText,
      roleOptions,
      editRole,
    };
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6a5af38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-input-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    "popup-key": _ctx.popupKey
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FlatButton, {
        onOnClick: _ctx.updateText,
        text: _ctx.$t('company.editConfirmButton'),
        class: "w-full"
      }, null, 8, ["onOnClick", "text"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("member.titleAcountType")), 1),
      _createVNode(_component_Dropdown, {
        "place-holder": _ctx.$t('member.titleAcountType'),
        options: _ctx.roleOptions,
        modelValue: _ctx.editRole,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editRole) = $event)),
        "item-text": "name",
        "item-value": "id"
      }, null, 8, ["place-holder", "options", "modelValue"])
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c5d02d4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-header big" }
const _hoisted_2 = { class: "section-body" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "empty-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ListItemEditInfo = _resolveComponent("ListItemEditInfo")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!
  const _component_UpdateLogoPopup = _resolveComponent("UpdateLogoPopup")!
  const _component_UpdateTextPopup = _resolveComponent("UpdateTextPopup")!
  const _component_UpdateNamePopup = _resolveComponent("UpdateNamePopup")!
  const _component_UpdateRoleMemberPopup = _resolveComponent("UpdateRoleMemberPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPage, { class: "home-admin-member-detail" }, {
      "top-part": _withCtx(() => [
        _createVNode(_component_BreadScrum, { paths: _ctx.databreadScrumLinks }, null, 8, ["paths"])
      ]),
      "left-top-part": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("member.titleProfileSetting")), 1)
      ]),
      "left-bottom-part": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoRows, (info, index) => {
            return (_openBlock(), _createBlock(_component_ListItemEditInfo, {
              key: index,
              "info-title": info.title,
              "info-value": info.value,
              "has-divider": "",
              onOnEdit: info.action,
              "disable-action": 
            (!_ctx.isEdit && !_ctx.memberData.canUpdate) || !_ctx.memberData.canUpdate
          
            }, {
              value: _withCtx(() => [
                (info.isLogo)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: 
                info.value 
                ? info.value + `?${Date.now()}`
                : require('icons/default-avatar.svg')
              ,
                      alt: "profile avatar",
                      class: "symbol-img bordered-avatar"
                    }, null, 8, _hoisted_3))
                  : (info.isTag)
                    ? (_openBlock(), _createBlock(_component_Tag, {
                        key: 1,
                        text: info.value
                      }, null, 8, ["text"]))
                    : (!info.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                      : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["info-title", "info-value", "onOnEdit", "disable-action"]))
          }), 128))
        ])
      ]),
      _: 1
    }),
    (_ctx.popupState.updateLogo)
      ? (_openBlock(), _createBlock(_component_UpdateLogoPopup, _mergeProps({ key: 0 }, _ctx.logoToEdit, {
          onLogoUpdated: _cache[0] || (_cache[0] = 
      () => {
        _ctx.getMemberDetail();
        _ctx.openNotification();
      }
    )
        }), null, 16))
      : _createCommentVNode("", true),
    (_ctx.popupState.updateText)
      ? (_openBlock(), _createBlock(_component_UpdateTextPopup, _mergeProps({ key: 1 }, _ctx.editTextPopupInfo, {
          onOnUpdateTextDone: _cache[1] || (_cache[1] = 
      () => {
        _ctx.getMemberDetail();
        _ctx.openNotification();
      }
    )
        }), null, 16))
      : _createCommentVNode("", true),
    (_ctx.popupState.updateName)
      ? (_openBlock(), _createBlock(_component_UpdateNamePopup, _mergeProps({ key: 2 }, _ctx.nameToEdit, {
          onOnUpdateNameDone: _cache[2] || (_cache[2] = 
      () => {
        _ctx.getMemberDetail();
        _ctx.openNotification();
      }
    )
        }), null, 16))
      : _createCommentVNode("", true),
    (_ctx.popupState.updateRoleMember)
      ? (_openBlock(), _createBlock(_component_UpdateRoleMemberPopup, {
          key: 3,
          id: _ctx.memberData.id,
          role: _ctx.memberData.role,
          title: _ctx.$t('member.editAcountType'),
          onOnUpdateRoleDone: _cache[3] || (_cache[3] = 
      () => {
        _ctx.getMemberDetail();
        _ctx.openNotification();
      }
    )
        }, null, 8, ["id", "role", "title"]))
      : _createCommentVNode("", true)
  ], 64))
}